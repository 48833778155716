<script>
import ChatButton from '@/components/ChatButton.vue';
import ChatWindow from '@/components/ChatWindow.vue';
import io from "socket.io-client";
import Echo from "laravel-echo";

window.io = io;
export default {
  components: {
    ChatButton,
    ChatWindow
  },
  props: {
    slug: {
      type: String,
      required: true
    },
    isInline: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      chatColor: this.color,
      isChatOpen: false,
      isLoading: true,
      settings: {
        color: '#7B68EE',
        assistant_name: '',
        image_file_url: '',
        welcome_message: '',
        isInline: false,
      },
    };
  },
  async mounted() {
    const echo = new Echo({
      broadcaster: "socket.io",
      host: 'https://api.lampabot.ru' + ':6001',
    });

    echo.channel(this.slug)
        .listen('.messageFromAssistant', (data) => {
          let uuid = localStorage.getItem('uid');
          if(data.uuid === uuid) {
            console.log(data.uuid);
            console.log(uuid);
            this.$refs.chatWindow.messageFromAssistant(data.message)
            // document.getElementById('notificationSound').play();
          }
        });

    this.settings = await this.getSettings();
  },
  methods: {
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    async getSettings() {
      try {
        const url = process.env.VUE_APP_API_URL + this.slug + '/settings';
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (!response.ok) {
          throw new Error('Ошибка');
        }

        const result = await response.json();
        this.isLoading = false;
        if (result.success) {
          return result.settings;
        } else {
          throw new Error('Ошибка');
        }
      } catch (error) {
        console.error('Ошибка отправки запроса:', error);
      }
    },
    hexToRgb(hex) {
      hex = hex.replace(/^#/, '');

      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      return {r, g, b};
    },

    darkenColor(color, percent) {
      const rgbColor = this.hexToRgb(color);
      if (percent < 0 || percent > 100) {
        throw new Error('Процент должен быть в диапазоне от 0 до 100');
      }

      let r = Math.round(rgbColor.r * (100 - percent) / 100);
      let g = Math.round(rgbColor.g * (100 - percent) / 100);
      let b = Math.round(rgbColor.b * (100 - percent) / 100);

      return `rgb(${r}, ${g}, ${b})`;
    }
  }
}
</script>

<template>
  <div v-if="!isLoading">
<!--    <audio id="notificationSound" preload="auto" src="https://chat.lampabot.ru/nm.mp3"></audio>-->
    <ChatButton :color="settings.color" :isChatOpen="isChatOpen" @click="toggleChat" :darkenColor="darkenColor(settings.color, 10)" v-if="!isInline" />
    <ChatWindow :color="settings.color" :activeColor="darkenColor(settings.color, 10)" :slug="slug" :isInline="isInline" :isShow="isInline || isChatOpen" :assistantAvatar="settings.image_file_url" :assistantName="settings.assistant_name" :welcomeMessage="settings.welcome_message" ref="chatWindow" />
  </div>
</template>
