<script>
import MessageIcon from 'vue-material-design-icons/Message.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';

export default {
  props: {
    color: {
      type: String,
      default: '#7B68EE', // Цвет нужен в HEX для вычисления % от цвета
    },
    isChatOpen: {
      type: Boolean,
      default: false,
    },
    darkenColor: {
      type: String,
      default: '#7B68EE',
    },
  },
  components: {
    MessageIcon,
    CloseIcon,
  },
  methods: {
    // Функция для вычисления % от цвет. Цвет указывать в HEX: #7B68EE
    calculateBotMessageColor(color) {
      // Парсинг цвета из формата hex в rgb
      const r = parseInt(color.slice(1, 3), 16); // Извлекаем первые 2 символа после #
      const g = parseInt(color.slice(3, 5), 16); // Извлекаем следующие 2 символа
      const b = parseInt(color.slice(5, 7), 16); // Извлекаем последние 2 символа

      const newColor = `rgba(${r}, ${g}, ${b}, 0.5)`; // Процент

      return newColor;
    },
    handleClick() {
      this.$emit('click');
    }
  }
}
</script>

<template>
  <div>
    <button class="button-style" :style="{ backgroundColor: $props.color, boxShadow: `4px 12px 16px ${calculateBotMessageColor($props.color)}` }" @click="handleClick">
      <div class="icon-container">
        <div v-if="!$props.isChatOpen">
          <message-icon />
        </div>
        <div v-else>
          <close-icon />
        </div>
      </div>
    </button>
  </div>
</template>

<style>

.button-style {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: fixed;
  bottom: 44px;
  right: 44px;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.4);
  z-index: 15;
  cursor: pointer;
}

.button-style:hover {
  background-color: v-bind('darkenColor') !important;
}

.icon-container {
  width: 100%;
  height: 50%;
}
</style>
